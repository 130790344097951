import React, { useEffect } from "react";
import { useDBStore } from "../../stores/dbStore";

const Divider = ({ id }) => {
  const { currentPage, pages, updateNode, handleMenuClick } = useDBStore(
    (state) => state
  );
  const node = pages[currentPage].nodes[id];
  useEffect(() => {
    if (!node.replaced) {
      updateNode(id, { replaced: true });
      handleMenuClick(null, id);
    }
  });
  return <div className="node w-full h-[2px] my-1 bg-gray-500/15"></div>;
};

export default Divider;
