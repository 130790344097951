import { useEffect, useRef, useState } from "react";
import { SiIconify } from "react-icons/si";
import { IoIosClose } from "react-icons/io";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useDBStore } from "../../stores/dbStore";

const EditorTitle = () => {
  const { createNewBlock, fetchEmojis, emojis, currentPage, updatePage, pages } = useDBStore(
    (state) => state
  );
  const [openDialog, setOpenDialog] = useState(false);
  const page = pages[currentPage];
  const element = useRef("");
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target;
      const selection = window.getSelection();
      let range = document.createRange();
      const startNode = selection.anchorNode;
      const startOffset = selection.anchorOffset;
      range.selectNodeContents(target);
      range.setStart(startNode, startOffset);
      selection.removeAllRanges();
      selection.addRange(range);
      range = selection.getRangeAt(0);
      const contents = range.extractContents();
      range.deleteContents();
      updatePage(currentPage, { title: target.textContent });
      const span = document.createElement("span");
      span.append(contents);
      createNewBlock("paragraph", 0, { text: span.textContent });
      span.remove();
    }
  };

  const handleSave = (e) => {
    const text = element.current.textContent;
    console.log("Blur Function");

    updatePage(currentPage, { title: text });
  };

  useEffect(() => {
    !emojis && fetchEmojis();
  }, [emojis])
  return (
    <div className="flex flex-col *:w-full mb-5 px-5">
      <div className="hover:text-slate-500">
        <button
          onClick={() => setOpenDialog(!openDialog)}
          className="w-auto text-white h-[50px] rounded-lg px-3 my-1 flex justify-evenly items-center hover:text-slate-500 hover:bg-slate-50"
        >
          <SiIconify />
          <span className="pl-2">Add Icon</span>
        </button>
      </div>

      <Dialog
        open={openDialog}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={() => setOpenDialog(false)}
        __demoMode
      >
        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full h-[fit-content] bg-slate-100 mr-80 max-w-md rounded-xl py-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
            >
              <DialogTitle
                as="div"
                className="text-base/7 pb-2 flex justify-between items-center font-medium text-gray-400 border-b border-b-neutral-300 px-6"
              >
                Add icon
                <IoIosClose size={24} onClick={() => setOpenDialog(false)} />
              </DialogTitle>
              <p className="mt-3 w-auto h-40 flex flex-wrap justify-stretch *:size-8 text-sm/6 text-black/50 ml-6 mr-4 overflow-y-scroll overflow-x-hidden">
                {emojis?.map(({ character, }) => {
                  return (
                    <span onClick={() => updatePage(currentPage, { icon: character })}>{character}</span>
                  )
                })}
              </p>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <div className="flex">
        <span className="text-[2.5rem] mr-2">{page.icon}</span>
        <span
          contentEditable={true}
          className="title font-extrabold w-full outline-none text-[2.5rem] whitespace-nowrap"
          aria-placeholder="Untitled"
          ref={element}
          onBlur={handleSave}
          onKeyUp={handleKeyDown}
          dangerouslySetInnerHTML={{ __html: page.title }}
        ></span>
      </div>
    </div>
  );
};

export default EditorTitle;
