import { Route, Routes, useParams } from "react-router-dom";
import Layout from "./layouts/BaseLayout";
import 'css-skeletons';
function App() {
  const { id } = useParams();
  return (
    <div className="flex mx-3">
      <Routes>
        <Route
          path="/:id?"
          loader={() => {
            console.log("Id", id);
          }}
          element={<Layout />}
        />
      </Routes>
    </div>
  );
}

export default App;
