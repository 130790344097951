import React, { useEffect, useState } from "react";
import MenuComponent from "./Menu";
import InlineToolbar from "./InlineToolbar";
import { RenderAllNodes } from "../utils";
import { useDBStore } from "../stores/dbStore";
import SubMenuComponent from "./SubMenu";
import { getAbsolutePosition } from "../helpers";
import { rangy } from "../DOM";
import Mention from "./plugins/Mention";

const Editor = () => {
  const {
    setShowInlineTool,
    doRedo,
    doUndo,
    setUndo,
    setInlineToolCords,
    currentPage,
    showInlineTool,
    setShowMention,
    setShowSubMenu,
    pages,
    initDB
  } = useDBStore((state) => state);
  const { activeNode, nodes } = pages[currentPage];
  const node = nodes[activeNode];

  const handleKeyDown = (e) => {
    const key = e.key;
    const ctrl = e.ctrlKey || e.metaKey;
    const shift = e.shiftKey;
    const selection = window.getSelection();
    const range = selection?.rangeCount > 0 ? selection.getRangeAt(0) : null;
    switch (key.toLowerCase()) {
      case "z":
        if (ctrl && !shift) {
          // console.log("Undo");
          e.preventDefault();
          doUndo();
          return;
        }
        if (ctrl && shift) {
          e.preventDefault();
          doRedo();
          return;
        }
        break;

      case "escape":
        e.preventDefault();
        setShowMention(null);
        setShowSubMenu(null);
        break;
      case "Backspace":
        const _range = document.createRange();
        const editor = document.querySelector("#editor");
        editor.classList.contains("selected") && e.preventDefault();
        _range.selectNodeContents();
        console.log("deleting All...");
        if (range.toString() === _range.toString()) {
          initDB();
        }
        break;
      case "a":
        if (ctrl && !shift && range) {
          const container = range.commonAncestorContainer.parentElement.closest('.nodeWrapper');
          if (container) {
            const editable = container.querySelector("[contenteditable=true]");
            if (editable.text === "") {
              e.preventDefault();
              const editor = document.querySelector("#editor");
              selection.removeAllRanges();
              selection.selectAllChildren(editor);
            }
            else {
              const _range = document.createRange();
              _range.selectNodeContents(editable);
              const selectAll = range.toString() === _range.toString();
              console.log(selectAll);
              if (selectAll) {
                e.preventDefault();
                const editor = document.querySelector("#editor");
                selection.removeAllRanges();
                selection.selectAllChildren(editor);
                editor.classList.add("selected")
              }

            }
          }
        }
        break
      default:
        break;
    }
  };

  const mouseUpFn = () => {
    if (showInlineTool) {
      const activeElement = document
        .querySelector(`.nodeWrapper[data-id="${activeNode}"]`)
        ?.querySelector("[contenteditable]");

      const selObj = rangy.getSelection();
      const _selection = activeElement
        ? rangy.serializeSelection(selObj, true, activeElement)
        : null;
      const data = {
        node: {
          ...node,
          text: activeElement?.innerHTML,
          selection: _selection,
        },
        focusedId: activeNode,
      };
      activeElement && setUndo(data);
    }
  };
  const handleSelection = () => {
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

    if (selection.type === "Range") {
      const cords = getAbsolutePosition(range);
      setInlineToolCords(cords);
      setShowInlineTool(true);
      document.addEventListener("mouseup", mouseUpFn, { once: true });
      return;
    }
    document.querySelector("#editor").classList.remove("selected");
    document.removeEventListener("mouseup", mouseUpFn, { once: true });
    const container = range?.commonAncestorContainer.parentElement;
    !container?.closest(".inline-tool") && setShowInlineTool(null);
  };

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelection, true);
    document.addEventListener("keydown", handleKeyDown, true);
  }, []);
  return (
    <div>
      <div id="editor">
        <RenderAllNodes />
      </div>
      <Mention />
      <MenuComponent />
      <SubMenuComponent />
      <InlineToolbar />
    </div>
  );
};

export default Editor;
