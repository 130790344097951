import { Input } from "@headlessui/react";
import { FaSearch } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { useDBStore } from "../../stores/dbStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar } from "@mui/joy";

const Mention = () => {
    const { pages,
        currentPage,
        mentionCords,
        showMention,
        setShowMention,
        users,
        searching,
        setSearching
    } = useDBStore((state) => state);
    const { nodes, activeNode } = pages[currentPage];
    const [allUsers, setAllUsers] = useState(null)


    const handleTyping = (e) => {
        // const key = e.key;
        // const block = document.querySelector("#mention");
        // const ablock = block.querySelector(".selected");
        // !ablock && block.querySelector(".user-list").classList.add("selected");
        // switch (key) {
        //     case 'Enter':
        //         e.preventDefault();
        //         break;
        //     case "ArrowUp":
        //         e.preventDefault();
        //         if (!ablock.previousElementSibling) return;
        //         ablock.classList.remove("selected");
        //         ablock.previousElementSibling?.classList.add("selected");
        //         break;

        //     case "ArrowDown":
        //         e.preventDefault();
        //         if (!ablock.nextElementSibling) return;
        //         ablock.classList.remove("selected");
        //         ablock.nextElementSibling?.classList.add("selected");
        //         break;
        // default:
        if (!!searching && allUsers) {
            const selection = getSelection();
            const _range = selection?.getRangeAt(0);
            const range = document.createRange();
            try {
                range.setStart(_range.startContainer, searching.startOffset); range.setEnd(_range.endContainer, _range.endOffset);
                if (_range.startOffset < searching.startOffset - 1) setShowMention(null);
                const keywords = range.toString();
                const filteredUsers = users.filter(({ name, username }) => username.toLowerCase().includes(keywords.toLowerCase()) || name.toLowerCase().includes(keywords.toLowerCase()));
                setAllUsers(filteredUsers);
            } catch (error) {
                setShowMention(null);
                setSearching(null);
            }
        }

        // break;
        // }
    }

    const handleUserClick = ({ id, username, name }) => {
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const _range = document.createRange();
        _range.setEnd(range.endContainer, range.endOffset)
        try {
            _range.setStart(range.startContainer, searching.startOffset - 1);
        } catch (error) {
            console.log("Error occured!", error);

            _range.setStart(range.startContainer, 0);
        }
        const a = document.createElement("a");
        a.setAttribute("contenteditable", false)
        a.href = `/users/${id}`;
        a.target = "_blank";
        a.title = name;
        a.style.color = "blue";
        _range.surroundContents(a);
        a.innerText = `@${username}`;
        selection.removeAllRanges();
        selection.addRange(_range);
        selection.collapseToEnd();
        setShowMention(null);
        setSearching(null);

    }



    useEffect(() => {
        const handleClick = (e) => {
            const target = e.target;
            const inMention = target.closest("#mention");
            !inMention && setShowMention(null);
        }

        if (showMention) {
            !!!allUsers && setAllUsers(users)
            document.addEventListener("keydown", handleTyping, true)
        }
        document.addEventListener("click", handleClick, true);
        return document.removeEventListener("click", handleClick, true);
    }, [users, allUsers]);

    return (
        <div
            style={{
                top: mentionCords.top + 15 + mentionCords.height,
                left: mentionCords.left - 135,
                display: showMention ? "flex" : "none"
            }}
            id="mention"
            className='not-selectable absolute scroll-m-1 overflow-y-auto z-50 border p-2 w-[250px] min-h-10 h-auto max-h-[300px] rounded-md flex-col bg-white'>
            {/* {showMention && (
                <span className='w-full h-[fit-content] py-2 px-1 mb-3 flex border-b border-b-gray-500/20'>
                    <FaSearch fill="#374151" className="self-center" />
                    <Input
                        autoFocus={true}
                        onInput={handleKeys}
                        placeholder="Search users"
                        className="w-full h-6 focus:outline-none rounded-md px-2 py-1"
                    />
                </span>
            )} */}
            <span className="w-full h-10 pb-2 px-2 mb-3 border-b inline-block font-light">
                Search Users
            </span>
            <div className='user-list size-full rounded-md'>
                {!allUsers ? (
                    <span className="w-full p-3 text-center h-auto max-h-6">Loading...</span>
                ) : (

                    allUsers.map(({ id, name, username }) => {
                        return (
                            <span key={id} tabIndex={0}
                                onClick={() => handleUserClick({ name, username, id })}
                                className='not-selectable select-none w-full h-auto p-2 flex hover:bg-gray-500/20 text-gray-700'>
                                <span className='rounded-full size-8 bg-blue-300'>
                                    {/* <FaCircleUser size={'100%'} fill="#374151" /> */}
                                    <Avatar src="https://via.placeholder.com/150/92c952" size="100%" />
                                </span>
                                <span className="flex flex-col *:w-full *:px-4">
                                    <small className='font-semibold'>@{username}</small>
                                    <small className='font-extralight text-[10px]'>{name}</small>
                                </span>
                            </span>
                        )
                    })

                )}

            </div>
        </div>
    )
}

export default Mention