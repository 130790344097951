import React, { useRef, useState } from "react";
import { IoBulbOutline } from "react-icons/io5";
import { useDBStore } from "../../stores/dbStore";
import ContentEditable from "../content/ContentEditable";

const Callout = ({ id, renderer }) => {
  const { pages, currentPage } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  return (
    <div
      key={id}
      className="flex w-full bg-red-500/15 rounded-md py-5 px-3"
    >
      <div className="w-[40px] h-full mr-3">
        <IoBulbOutline className="size-[20px]" />
      </div>
      <div className="w-full">
        <ContentEditable
          key={id}
          id={id}
          className="flex w-full outline-none text-normal"
          aria-placeholder="Type Something..."
        />
        <div key={id + "2"} className="flex flex-col pt-3">
          {node.children.map((childId) => {
            return renderer(childId, nodes[childId].type);
          })}
        </div>
      </div>
    </div>
  );
};

export default Callout;
