import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { CiAlignLeft, CiAlignCenterH, CiAlignRight } from "react-icons/ci";
import { getAbsolutePosition } from "../../helpers";
import { IoIosClose } from "react-icons/io";
import { useDBStore } from "../../stores/dbStore";
import { Dropzone } from "dropzone";


const ImageDialog = () => {
  const { imageDialogIsOpen, setImageDialogIsOpen } = useDBStore((state) => state)
  const $this = useRef(null);

  useEffect(() => {
    if ($this.current) {
      // console.log($this.current.querySelector("button.dropzone"));
      const dropzone = !!window.Dropzone && new Dropzone($this.current.querySelector("button.dropzone"), { url: "/uploads/image" });
    }

  })
  return (
    <div
      ref={$this}
      style={{ display: imageDialogIsOpen ? "flex" : "none" }}
      className="img-dialog select-none absolute flex-col border z-50 h-[100px] w-[600px] rounded-md bg-white">
      <div className="w-full px-2 py-1 border-b h-8 flex justify-between">
        <span>Upload an Image</span> <IoIosClose className="m-1.5" onClick={() => setImageDialogIsOpen(null)} />
      </div>
      <div className="p-4">
        <button
          className="dropzone w-full m-auto rounded-md p-1.5 bg-gray-500/90">
          Upload
        </button>
      </div>
    </div>
  );
};

const Image = ({ id }) => {
  const { imageDialogIsOpen, setImageDialogIsOpen, updateNode, currentPage, pages } = useDBStore((state) => state)
  const { nodes, activeNode } = pages[currentPage];
  const node = nodes[id];
  const $this = useRef(null);

  useEffect(() => {
    if ($this.current) {
      const cords = getAbsolutePosition($this.current)
      const dialog = $this.current.querySelector(".img-dialog");
      dialog.style.top = `${cords.top + 100}px`;
      dialog.style.left = `${cords.left - 100}px`;
    }
  }, [node.styles]);
  return (
    <>
      <div ref={$this}
        className="flex flex-col m-auto justify-center w-full max-w-full min-h-10 max-h-[300px]">
        <div style={{ ...node.styles }}
          className="flex w-full px-1">
          <div className="not-selectable z-[52] group flex items-center">
            <div className="relative flex -top-[45%] -right-[80%] ml-auto  justify-end pr-3 w-[80px] h-5 bg-transparent z-10  mr-2 *:mx-1.5 *:hidden *:group-hover:inline">
              <span onClick={() => updateNode(id, { styles: { justifyContent: "start" } })} >
                <CiAlignLeft />
              </span>
              <span onClick={() => updateNode(id, { styles: { justifyContent: "center" } })}>
                <CiAlignCenterH />
              </span>
              <span onClick={() => updateNode(id, { styles: { justifyContent: "end" } })}>
                <CiAlignRight />
              </span>
            </div>
            <img
              onClick={() => setImageDialogIsOpen(!imageDialogIsOpen)}
              className="max-h-[300px] max-w-full"
              src="/test_image.png"
              alt="image"
            />
            <div className="flex items-center h-full w-1 cursor-col-resize rounded-t-full rounded-b-full">
              <span className="hidden group-hover:inline-block h-[40%] w-full bg-gray-500/40">
                &nbsp;
              </span>
            </div>
          </div>
        </div>
        <ImageDialog />
      </div>
    </>
  );
};

export default Image;
