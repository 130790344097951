import React, { useEffect, useRef } from "react";
import { useDBStore } from "../../stores/dbStore";
import { ColumnResizer } from "@column-resizer/core";

const HorizontalBlock = ({ id, renderer }) => {
  const { pages, currentPage, setDestination } = useDBStore((state) => state);
  const $this = useRef(null);
  const { nodes, focusedNode } = pages[currentPage];
  const node = nodes[id];

  const handleDragOver = (e) => {
    const hBlock = e.target.previousElementSibling;
    const y = e.clientY;
    const rects = e.target.getBoundingClientRect();
    const id = hBlock.getAttribute("data-id");
    if (Math.abs(rects.bottom - y) < 40) {
      document.querySelector(".activeBlock")?.classList.remove("activeBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
      e.target.classList.add("activeBlock");
      setDestination(id);
      return;
    }
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
  };

  const handleDragLeave = () => {
    const target = document.querySelector(".activeWrapper");
    target?.classList.remove("activeWrapper");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    // setDestination(null);
  };

  useEffect(() => {
    if ($this.current) {
      const columnResizer = new ColumnResizer({ vertical: false });
      columnResizer.init($this.current);
    }
  });

  return (
    <>
      <div
        key={id}
        data-id={id}
        ref={$this}
        className="flex flex-row w-full min-h-4"
      >
        {node.children.map((childId) => {
          return renderer(childId, nodes[childId].type);
        })}
      </div>
      <div
        className="w-full min-h-0.5"
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
      ></div>
    </>
  );
};

export default HorizontalBlock;
