import React, { memo, useEffect, useRef, useState } from "react";
import { useDBStore } from "../stores/dbStore";
import { SubMenus } from "../constants";
import { Input } from "@headlessui/react";
import { FaChevronRight } from "react-icons/fa6";
import { Menus } from "../constants";

const ExtraMenu = ({ children }) => {
  return (
    <div className="hidden absolute z-30 -top-24 left-[250px] group-hover:block size-auto">
      {children}
    </div>
  );
};

const SubMenuComponent = () => {
  const {
    createNewBlock,
    focusCords,
    setTurnMenu,
    createNode,
    setShowMenu,
    showMenu,
    showMenuButtons,
    replaceNode,
    setDragged,
    setDragging,
    destinationNode,
    moveNode,
    draggedNode,
    updateParent,
    deleteNode,
    focusedNode,
    setActiveNode,
    setFocus,
    setShowMenuButtons,
    node2VBlock,
    pages,
    currentPage,
    moveBlock,
    showSubMenu,
    copyNode,
  } = useDBStore((state) => state);
  const { activeNode, nodes, blocks } = pages[currentPage];
  const [MenuItems, setMenuItems] = useState(SubMenus);
  const searchRef = useRef(null);

  const handleInput = (e) => {
    const text = e.target.value;
    const menus = !!text
      ? [...MenuItems]
      : [
          ...MenuItems.filter((item) =>
            item.name ? item.name.includes(text) : false
          ),
        ];
    setMenuItems(menus);
  };

  const handleClick = (e, name) => {
    e.preventDefault();
    const node = nodes[activeNode];
    const nodeParent = nodes[node.parent];
    const nodeGParent = nodes[nodeParent.parent];
    switch (name.toLowerCase()) {
      case "delete":
        updateParent(activeNode);
        deleteNode(activeNode);
        setActiveNode(null);
        setFocus(null);
        break;

      case "move up":
        if (
          nodeParent.type === "verticalBlock" &&
          nodeGParent.children.length === 1
        ) {
          //Its a block Node
          const blockIndex = Math.max(0, blocks.indexOf(nodeGParent.id) - 1);
          moveBlock(nodeGParent.id, blockIndex);
        }
        break;

      case "duplicate":
        copyNode(activeNode);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    if (searchRef.current) searchRef.current.focus();
  });
  return (
    <div>
      <div
        style={{
          top: focusCords.top - 50,
          left: focusCords.left - 270,
        }}
        className="absolute left-10 w-[250px] z-30"
      >
        <div
          style={{ display: showSubMenu ? "block" : "none" }}
          className="h-auto bg-white border-b-gray-400/40 border left-0 w-full m-auto rounded-lg"
        >
          <div className="size-full h-12 pt-3 px-3 border-b rounded-t-md flex items-center text-sm text-gray-500/80">
            <Input
              ref={searchRef}
              autoFocus={true}
              className="w-full py-2 px-1.5 mb-2 rounded-md outline-none border border-gray-400/20"
              onInput={handleInput}
            />
          </div>
          {MenuItems.length === 0 && (
            <div key={890} className="size-full h-10 p-3 border-b rounded-t-md flex items-center text-sm text-gray-500/80">
              No Result Found
            </div>
          )}
          <div className="menuitems flex-col m-auto pt-3 border-t-gray-400/40 h-auto w-full hidden has-[div]:flex">
            {MenuItems.map(
              ({ name, icon, cmd, shortcut, separator, menu }, i) => {
                return !separator ? (
                  <div
                    key={i}
                    tabIndex={0}
                    className="group w-full flex flex-col"
                  >
                    <div
                      onClick={(e) => handleClick(e, name)}
                      className="flex w-full h-[35px] px-2 focus:bg-slate-300/20 group-has-[.selected]:bg-slate-300/20 focus:outline-none hover:bg-slate-300/20 *:my-auto justify-between"
                    >
                      <span className="h-[35px] inline-flex  text-gray-500/80 w-[35px] p-2 justify-center items-center rounded-md mr-2.5 *:size-[80px]">
                        {icon}
                      </span>
                      <span className="w-full h-full inline-flex flex-col justify-center *:text-left font-light">
                        <span className="inline-block text-gray-500/90">
                          {name}
                        </span>
                      </span>
                      {!menu ? (
                        <span className="h-full inline-flex w-full justify-center items-center">
                          <kbd className="keyboard ml-auto font-sans text-xs inline text-gray-500/90">
                            {shortcut}
                          </kbd>
                        </span>
                      ) : (
                        <span className="text-gray-500/90 pr-2">
                          <FaChevronRight />
                        </span>
                      )}
                    </div>
                    {menu && <ExtraMenu>{menu}</ExtraMenu>}
                  </div>
                ) : (
                  <hr className="mx-2" />
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubMenuComponent;
