import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { v4 as uuidv4 } from "uuid";
import { Structures } from "../constants";
import { findElementByIndex, findNodeIndex, getAbsolutePosition, insertAt } from "../helpers";
import { rangy } from "../DOM";

export const useDBStore = create(
  immer((set, get) => ({
    initDB: () =>
      set((state) => {
        state.pages[get().currentPage] = {};
        state.pages[get().currentPage].blocks = [];
        state.pages[get().currentPage].nodes = {};
        state.pages[get().currentPage].undos = [{ nodes: {}, blocks: [] }];
        state.pages[get().currentPage].focusedNode = null;
        state.pages[get().currentPage].currentPage = null;
        state.pages[get().currentPage].currentCursor = 1;
        state.pages[get().currentPage].title = null;
        state.pages[get().currentPage].parent = get().previousPage;
        state.pages[get().currentPage].href = "/Untitled--" + get().currentPage;
      }),
    currentPage: null,
    setCurrentPage: (pageId) =>
      set((state) => {
        state.currentPage = pageId;
      }),
    setPreviousPage: (id) =>
      set((state) => {
        state.previousPage = id;
      }),
    history: [],
    setHistory: (history) =>
      set((state) => {
        state.history = history;
      }),
    // nodes: {
    //   "fl-354ee16c-bfdd-44d3-afa9-e93679bda367": {
    //     id: "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //     type: "horizontalBlock",
    //     children: [
    //       "fl-354ee16c-efdd-44d3-afa9-e09679bda367",
    //       "fl-354ee16c-efdd-4883-afa9-e09679bda367",
    //     ],
    //   },
    //   "fl-354ee16c-efdd-44d3-afa9-e09679bda367": {
    //     id: "fl-354ee16c-efdd-44d3-afa9-e09679bda367",
    //     type: "verticalBlock",
    //     parent: "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //     children: ["fl-354ee16c-efdd-fffe-afa9-e0967de3a367"],
    //   },
    //   "fl-354ee16c-efdd-fffe-afa9-e0967de3a367": {
    //     id: "fl-354ee16c-efdd-fffe-afa9-e0967de3a367",
    //     type: "callout",
    //     parent: "fl-354ee16c-efdd-44d3-afa9-e09679bda367",
    //     children: ["fl-354ee16c-efdd-6433-afa9-e09546bda367"],
    //   },

    //   "fl-354ee16c-efdd-6433-afa9-e09546bda367": {
    //     id: "fl-354ee16c-efdd-6433-afa9-e09546bda367",
    //     type: "todo",
    //     parent: "fl-354ee16c-efdd-fffe-afa9-e0967de3a367",
    //     children: [],
    //   },

    //   "fl-354ee16c-efdd-4883-afa9-e09679bda367": {
    //     id: "fl-354ee16c-efdd-4883-afa9-e09679bda367",
    //     type: "verticalBlock",
    //     parent: "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //     children: ["fl-3547616c-efdd-eefe-afa9-e0967de3a367"],
    //   },
    //   "fl-3547616c-efdd-eefe-afa9-e0967de3a367": {
    //     id: "fl-3547616c-efdd-eefe-afa9-e0967de3a367",
    //     type: "callout",
    //     parent: "fl-354ee16c-efdd-4883-afa9-e09679bda367",
    //     children: ["fl-354ee16c-efdd-6433-eda9-e09546bda367"],
    //   },
    //   "fl-354ee16c-efdd-6433-eda9-e09546bda367": {
    //     id: "fl-354ee16c-efdd-6433-eda9-e09546bda367",
    //     type: "list",
    //     parent: "fl-3547616c-efdd-eefe-afa9-e0967de3a367",
    //     children: [
    //       "fl-354ee16c-efdd-6433-4329-e09546bdded7",
    //       "fl-354ee16c-efdd-fff3-4329-e094532dded7",
    //     ],
    //   },
    //   "fl-354ee16c-efdd-6433-4329-e09546bdded7": {
    //     id: "fl-354ee16c-efdd-6433-4329-e09546bdded7",
    //     type: "todo",
    //     parent: "fl-354ee16c-efdd-6433-eda9-e09546bda367",
    //     children: [],
    //   },
    //   "fl-354ee16c-efdd-fff3-4329-e094532dded7": {
    //     id: "fl-354ee16c-efdd-fff3-4329-e094532dded7",
    //     type: "list",
    //     parent: "fl-354ee16c-efdd-6433-eda9-e09546bda367",
    //     children: [],
    //   },

    //   "fl-354ee16c-bfdd-44d3-afa9-e93679b64367": {
    //     id: "fl-354ee16c-bfdd-44d3-afa9-e93679b64367",
    //     type: "horizontalBlock",
    //     children: ["fl-354ee16c-efdd-44d3-afa9-e0967de3a367"],
    //   },
    //   "fl-354ee16c-efdd-44d3-afa9-e0967de3a367": {
    //     id: "fl-354ee16c-efdd-44d3-afa9-e0967de3a367",
    //     type: "verticalBlock",
    //     parent: "fl-354ee16c-bfdd-44d3-afa9-e93679b64367",
    //     children: ["fl-354ee16c-efdd-fffe-afa9-e0967de35437"],
    //   },
    //   "fl-354ee16c-efdd-fffe-afa9-e0967de35437": {
    //     id: "fl-354ee16c-efdd-fffe-afa9-e0967de35437",
    //     text: "",
    //     type: "heading",
    //     element: "h2",
    //     parent: "fl-354ee16c-efdd-44d3-afa9-e0967de3a367",
    //     children: [],
    //   },
    // },
    nodes: {},
    updateNode: (id, data) =>
      set((state) => {
        console.log("Saving...");
        state.pages[get().currentPage].nodes[id] = {
          ...state.pages[get().currentPage].nodes[id],
          ...data,
        };
      }),
    batchUpdate: (data = []) =>
      set((state) => {
        data.map((d) => {
          return (state.pages[get().currentPage].nodes[d.id] = {
            ...state.pages[get().currentPage].nodes[d.id],
            ...d,
          });
        });
      }),

    deleteNode: (id) =>
      set((state) => {
        delete state.pages[get().currentPage].nodes[id];
      }),

    deleteAll: () =>
      set((state) => {
        state.pages[get().currentPage].nodes = {};
        state.pages[get().currentPage].blocks = [];
      }),

    // blocks: [
    //   "fl-354ee16c-bfdd-44d3-afa9-e93679bda367",
    //   "fl-354ee16c-bfdd-44d3-afa9-e93679b64367",
    // ],
    blocks: [],
    updateBlock: (newBlock) =>
      set((state) => {
        state.pages[get().currentPage].blocks = newBlock;
      }),
    deleteBlock: (id) =>
      set((state) => {
        if (get().pages[get().currentPage].blocks.indexOf(id) === -1) return;
        state.pages[get().currentPage].blocks = [
          ...get().pages[get().currentPage].blocks.filter((i) => i !== id),
        ];
      }),

    pages: {},
    updatePage: (id, data) =>
      set((state) => {
        console.log("Updating Page:", data);
        state.pages[id] = { ...state.pages[id], ...data };
      }),
    focusedNode: null, //The Node with cursor
    setFocus: (id) =>
      set((state) => {
        state.pages[get().currentPage].focusedNode = id;
      }),

    activeNode: null, // The node that is to be recreated from the menu
    setActiveNode: (id) =>
      set((state) => {
        state.pages[get().currentPage].activeNode = id;
      }),

    focusCords: { top: 0, left: 0, height: 0 }, // The coordinates of the menu buttons top: 0, left: 0, height: 1 menuHeigth: 1
    setFocusCords: (cords) =>
      set((state) => {
        state.focusCords = cords;
      }),
    showMenu: null,
    setShowMenu: (status) =>
      set((state) => {
        state.showMenu = status;
      }),
    showSubMenu: null,
    setShowSubMenu: (status) =>
      set((state) => {
        state.showSubMenu = status;
      }),
    showTurnMenu: null,
    setTurnMenu: (status) =>
      set((state) => {
        state.showTurnMenu = status;
      }),
    showMenuButtons: null,
    setShowMenuButtons: (status) =>
      set((state) => {
        state.showMenuButtons = status;
      }),
    showInlineTool: null,
    setShowInlineTool: (status) =>
      set((state) => {
        state.showInlineTool = status;
      }),
    inlineToolCords: { top: 0, left: 0, height: 0 }, // The coordinates of the Toolbar buttons top: 0, left: 0, height: 1 menuHeigth: 1
    setInlineToolCords: (cords) =>
      set((state) => {
        state.inlineToolCords = cords;
      }),
    showMention: null,
    setShowMention: (status) => set((state) => {
      state.showMention = status
    }),

    mentionCords: { top: 0, left: 0, height: 0 }, // The coordinates of the menu buttons top: 0, left: 0, height: 1 menuHeigth: 1
    setMentionCords: (cords) =>
      set((state) => {
        state.mentionCords = cords;
      }),

    draggedNode: null, // The Node currently being dragged
    setDragged: (id) =>
      set((state) => {
        state.draggedNode = id;
      }),

    destinationNode: null, // The Node to drop the drag
    setDestination: (id) =>
      set((state) => {
        state.destinationNode = id;
      }),

    hoverNode: null, // The currently Hovered Node
    setHover: (id) =>
      set((state) => {
        state.hoverNode = id;
      }),
    dragging: false, // Are we dragging?
    setDragging: (status) =>
      set((state) => {
        state.dragging = status;
      }),

    searching: false, // Are we searching?
    setSearching: (status) =>
      set((state) => {
        state.searching = status;
      }),

    keyWords: "", // The Typed word
    setKeywords: (text) =>
      set((state) => {
        state.keyWords = text;
      }),

    updateParent: (id) => {
      const parentId = get().pages[get().currentPage].nodes[id].parent;
      const parent = get().pages[get().currentPage].nodes[parentId];
      const children = [...parent.children.filter((child) => child !== id)];
      if (children.length > 0) {
        get().updateNode(parentId, { children });
        return;
      }
      if (parent.type === "verticalBlock") {
        const pParentId = parent.parent;
        const pParent = get().pages[get().currentPage].nodes[pParentId];
        get().deleteNode(parentId);
        const c = [...pParent.children.filter((i) => i !== parentId)];
        if (c.length === 0) {
          get().deleteBlock(pParentId);
          get().deleteNode(pParentId);
          return;
        }
        get().updateNode(pParentId, { children: c });
      }
    },

    createNewBlock: (
      type = "paragraph",
      index = 0,
      data = {},
      popMenu = false
    ) => {
      const nodeId = data.id ?? "fl-" + uuidv4();
      const vId = "fl-" + uuidv4();
      const hId = "fl-" + uuidv4();

      let node = Structures[type];
      node = typeof node === "function" ? node() : node;
      node = { ...node, id: nodeId, ...data, parent: vId };
      const vBlock = {
        ...Structures["verticalBlock"],
        ...{ id: vId, children: [nodeId], parent: hId },
      };
      const hBlock = {
        ...Structures["horizontalBlock"],
        ...{ id: hId, children: [vId] },
      };
      get().batchUpdate([node, vBlock, hBlock]);
      get().updateBlock(
        insertAt(hId, get().pages[get().currentPage].blocks, index)
      );
      get().setFocus(nodeId);
      get().setActiveNode(nodeId);
      get().setShowMenuButtons(popMenu);
    },

    createNode: (
      type = "paragraph",
      parentId,
      index = 0,
      data = {},
      popMenu = false
    ) => {
      const nodeId = "fl-" + uuidv4();
      let node = Structures[type];
      node = typeof node === "function" ? node() : node;
      node = { ...node, ...data, id: nodeId, parent: parentId };
      const parent = get().pages[get().currentPage].nodes[parentId];
      get().batchUpdate([
        node,
        { ...parent, children: insertAt(nodeId, parent.children, index) },
      ]);
      get().setFocus(nodeId);
      get().setActiveNode(nodeId);
      get().setShowMenuButtons(popMenu);
      return;
    },

    moveBlock: (id, index = 0) => {
      get().updateBlock([
        insertAt(id, get().pages[get().currentPage].blocks, index),
      ]);
    },

    moveNode: (id, newSiblingId, index = 0, parent = false) => {
      const newParentId = !parent
        ? get().pages[get().currentPage].nodes[newSiblingId].parent
        : newSiblingId;
      const newParent = get().pages[get().currentPage].nodes[newParentId];
      get().batchUpdate([
        { ...get().pages[get().currentPage].nodes[id], parent: newParentId },
        {
          ...newParent,
          children: insertAt(id, newParent.children, index),
        },
      ]);
    },

    copyNode: (id) => {
      const pages = get().pages[get().currentPage];
      const nodes = pages.nodes;
      const node = nodes[id];
      const newId = "fl-" + uuidv4();
      const nodeParent = nodes[node.parent];
      const nodeGParent = nodes[nodeParent.parent];
      if (
        nodeGParent.children.length === 1 &&
        nodeParent.type === "verticalBlock"
      ) {
        // Its a Block
        const blocks = pages.blocks;
        const index = blocks.indexOf(nodeGParent.id) + 1;

        get().updateNode(newId, { ...nodeGParent, id: newId });
        get().updateBlock(insertAt(newId, blocks, index));
      } else {
        // Its a node
        const index = nodeParent.children.indexOf(id) + 1;
        get().updateNode(newId, { ...node, id: newId });
        get().updateNode(node.parent, {
          ...nodeParent,
          children: insertAt(newId, nodeParent.children, index),
        });
      }
      const newNode = get().pages[get().currentPage].nodes[newId];
      get().duplicateNode(newNode);
    },

    duplicateNode: (node) => {
      const page = get().pages[get().currentPage];
      const nodes = page.nodes;
      const children = [];
      if (node?.children?.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          const childId = node.children[i];
          const newId = "fl-" + uuidv4();
          const childNode = nodes[childId];
          children.push(newId);
          if (childNode?.children?.length > 0) {
            get().duplicateNode({ ...childNode, id: newId, parent: node.id });
          } else {
            get().updateNode(newId, {
              ...childNode,
              id: newId,
              parent: node.id,
            });
            get().setActiveNode(newId);
            get().setFocus(newId);
          }
        }
      }
      get().updateNode(node.id, {
        ...node,
        children,
      });
    },

    replaceNode: (id, type, data = {}) =>
      set((state) => {
        let newNode = Structures[type];
        newNode = typeof newNode === "function" ? newNode() : newNode;
        const node = get().pages[get().currentPage].nodes[id];
        state.pages[get().currentPage].nodes[id] = {
          ...newNode,
          ...node,
          ...data,
          type,
        };
        state.activeNode = id;
        state.showMenuButtons = false;
      }),

    node2VBlock: (nodeId, siblingVBlock) => {
      const vId = "fl-" + uuidv4();
      const node = get().pages[get().currentPage].nodes[nodeId];
      const newHParentId =
        get().pages[get().currentPage].nodes[siblingVBlock].parent;
      const newParent = get().pages[get().currentPage].nodes[newHParentId];
      const index = newParent.children.indexOf(siblingVBlock) + 1;
      const vBlock = {
        ...Structures["verticalBlock"],
        id: vId,
        children: [nodeId],
        parent: newHParentId,
      };
      get().batchUpdate([
        { ...node, parent: vId },
        vBlock,
        {
          ...newParent,
          children: insertAt(vId, newParent.children, index),
        },
      ]);
      get().setActiveNode(nodeId);
      get().setFocus(nodeId);
      document.querySelector(".activeHBlock")?.classList.remove("activeHBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
    },
    createNodeOrBlock: (id, data = {}, type = null) => {
      const nodes = get().pages[get().currentPage].nodes;
      const node = nodes[id];
      if (!get().pages[get().currentPage].focusedNode) return;
      // type = type ?? node.type;
      if (!type) {
        const x = ["todo", "toggle", "list"].indexOf(node.type) < 0;
        type = x ? "paragraph" : node.type;
      }

      const nodeParentId = node.parent;
      const nodeParent = nodes[nodeParentId];

      const vertical = nodeParent.type === "verticalBlock";
      const npParentId = nodeParent.parent;
      const npParent = nodes[npParentId];
      const onlyChild = npParent.children.length === 1; // Means the Node is the only child
      if (onlyChild && vertical) {
        // The node and its parent are alone. meaning its a block
        const index =
          get().pages[get().currentPage].blocks.indexOf(npParentId) + 1;
        get().createNewBlock(type, index, data);
        return;
      }
      const index = nodeParent.children.indexOf(node.id) + 1;
      get().createNode(type, nodeParentId, index, data);
      return;
    },

    handleMenuClick: (e = null, activeNode = null) => {
      e?.preventDefault();
      const page = get().pages[get().currentPage];
      const nodes = page.nodes;
      const blocks = page.blocks;
      activeNode = activeNode ?? page.activeNode;
      const node = nodes[activeNode];
      const nodeParentId = node.parent;
      const nodeParent = nodes[nodeParentId];
      const vertical = nodeParent.type === "verticalBlock";

      const npParentId = nodeParent.parent;
      const npParent = nodes[npParentId];
      const onlyChild = npParent.children.length === 1; // Means the Node is the only child

      if (onlyChild && vertical) {
        // The node and its parent are alone. meaning its a block
        const index = blocks.indexOf(npParentId) + 1;
        if (node.type === "paragraph" && node.text === "" && e) return;

        get().createNewBlock("paragraph", index); //type index data
        return;
      }
      const index = nodeParent.children.indexOf(activeNode) + 1;
      if (node.type === "paragraph" && node.text === "" && e) return;
      get().createNode("paragraph", nodeParentId, index);
    },

    handleKeyUps: (e) => {
      const target = e.target;
      let text = target.textContent;
      const selection = window.getSelection();
      const startNode = selection.anchorNode;
      const startOffset = selection.anchorOffset;
      let range = document.createRange();
      const wrapper = target.closest(".nodeWrapper");
      const id = wrapper.getAttribute("data-id"); // Can just get it from get().focusNode
      // const node = get().pages[get().currentPage].nodes[id];
      const body = document.querySelector("body");
      const menuIsOpened = body.classList.contains("menu-opened"); // Without this, the Keydown Event for ENTER will fire and it will recreate another extra Block/Node

      switch (e.key) {
        case "Enter":
          get().updateNode(id, { text: target.innerHTML });
          if (text === "") {
            !menuIsOpened && get().createNodeOrBlock(id);
          } else {
            range.selectNodeContents(target);
            range.setStart(startNode, startOffset);
            selection.removeAllRanges();
            selection.addRange(range);
            range = selection.getRangeAt(0);
            const contents = range.extractContents();
            range.deleteContents();
            const span = document.createElement("span");
            span.append(contents);
            get().createNodeOrBlock(id, { text: span.innerHTML });
            span.remove();
          }
          break;

        case "@":
          const _range = selection?.rangeCount > 0 ? selection.getRangeAt(0) : null;
          if (!_range) return;
          get().fetchUsers();
          const cords = getAbsolutePosition(_range);
          get().setMentionCords(cords);
          get().setShowMention(true);
          get().setSearching({ startOffset: _range.startOffset });
          break;

        default:
          break;
      }
      body.classList.remove("menu-opened");
    },

    handleKeyDowns: (e) => {
      const target = e.target;
      const id = target.closest(".nodeWrapper").getAttribute("data-id");
      const node = get().pages[get().currentPage].nodes[id];
      const text = target.textContent;
      const html = target.innerHTML;
      const selection = window.getSelection();
      const selectedRange =
        selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
      if (!selectedRange) return;
      const range = document.createRange();
      range.selectNode(target.lastChild || target);
      const offset =
        selectedRange.endContainer === target.lastChild
          ? target.lastChild.textContent.length
          : target.textContent.length;

      const atStart =
        selectedRange.startContainer === target.firstChild &&
        selectedRange.endOffset === 0;

      const isEnd =
        selectedRange.endContainer === selectedRange.startContainer &&
        selectedRange.endOffset === offset;

      if (!selectedRange) return;
      const endOffset = selection.focusOffset;

      let nodeIndex = findNodeIndex(target, -1);
      const prevNode = findElementByIndex(nodeIndex);
      nodeIndex = findNodeIndex(target, 1);
      const nextNode = findElementByIndex(nodeIndex);

      const body = document.querySelector("body");
      const menuIsOpened = body.classList.contains("menu-opened"); // Without this, the Keydown Event for ENTER will fire and it will recreate another extra Block/Node
      const specialKeys = [
        "/",
        "Delete",
        "Backspace",
        "ArrowRight",
        "ArrowLeft",
        "ArrowUp",
        "ArrowDown",
        "Tab",
      ];

      if (menuIsOpened && specialKeys.indexOf(e.key)) return;
      switch (e.key) {
        case "/":
          if (text === "") {
            e.preventDefault();
            const clickEvent = new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            });
            document.querySelector("#menuBtn")?.dispatchEvent(clickEvent);
          }
          break;
        case "Enter":
          e.preventDefault();
          selectedRange.deleteContents();
          break;

        case "ArrowLeft":
          if ((atStart || text === "") && prevNode && prevNode !== target) {
            e.preventDefault();
            selection.removeAllRanges();
            selection.selectAllChildren(prevNode);
            selection.collapseToEnd();
          }
          break;

        case "ArrowRight":
          if (isEnd || (text === "" && nextNode)) {
            e.preventDefault();
            nextNode && selection.removeAllRanges();
            nextNode?.focus();
          }
          break;

        case "Backspace":
          const editor = document.querySelector("#editor");
          if (editor.classList.contains("selected")) {
            e.preventDefault();
            // get().setUndo();
            get().initDB();
            return;
          }

          if (atStart || text === "") {
            e.preventDefault();
            if (node.type !== "paragraph") {
              get().replaceNode(id, "paragraph", { text: target.innerHTML });
            } else {
              if (prevNode && prevNode !== target) {
                prevNode.focus();
                range.selectNodeContents(prevNode);
                selection.addRange(range);
                selection.collapseToEnd();
                prevNode.insertAdjacentHTML("beforeend", html);
                get().updateParent(id);
                get().deleteNode(id);
                get().setShowMenuButtons(null);
              }
            }
          }

          break;

        case "Delete":
          if (isEnd && nextNode) {
            e.preventDefault();
            if (nextNode?.textContent === "") {
              const nId = nextNode
                .closest(".nodeWrapper")
                .getAttribute("data-id");
              get().updateParent(nId);
              get().updateNode(nId);
            } else {
              e.preventDefault();
              const nId = nextNode
                .closest(".nodeWrapper")
                .getAttribute("data-id");
              range.selectNodeContents(target);
              selection.removeAllRanges();
              selection.addRange(range);
              selection.collapseToEnd();
              target.insertAdjacentHTML("beforeend", nextNode.innerHTML);
              get().updateParent(nId);
              get().deleteNode(nId);
            }
          }
          break;

        case "ArrowUp":
          e.preventDefault();
          const focusFnUp = (i) => {
            try {
              range.setStart(prevNode.lastChild ?? prevNode, i);
              range.setEnd(prevNode.lastChild ?? prevNode, i);
              range.collapse();
              selection.removeAllRanges();
              selection.addRange(range);
              return;
            } catch (error) {
              // i = Math.max(0, i - 1);
              // focusFnUp(i);
              range.selectNodeContents(prevNode.lastChild ?? prevNode);
              range.collapse(false);
              selection.removeAllRanges();
              selection.addRange(range);
            }
          };
          prevNode && focusFnUp(endOffset);

          break;

        case "ArrowDown":
          e.preventDefault();
          const focusFnDown = (i) => {
            try {
              range.setStart(nextNode.lastChild ?? nextNode, i);
              range.setEnd(nextNode.lastChild ?? nextNode, i);
              range.collapse();
              selection.removeAllRanges();
              selection.addRange(range);
              return;
            } catch (error) {
              i = Math.max(0, i - 1);
              focusFnDown(i);
            }
          };
          nextNode && focusFnDown(endOffset);

          break;

        case "Tab":
          e.preventDefault();
          const pId = prevNode.closest(".nodeWrapper").getAttribute("data-id");
          if (pId === get().pages[get().currentPage].nodes[id].parent) return;
          const pNode = get().pages[get().currentPage].nodes[pId];
          const index = pNode.children.length;
          const selObj = rangy.getSelection();
          const sel = rangy.serializeSelection(selObj, true, target);
          get().updateNode(id, { text: target.innerHTML, selection: sel });
          get().updateParent(id);
          get().moveNode(id, pId, index, true);
          break;
        default:
          break;
      }
    },

    handlePaste: (e) => {
      e.preventDefault();
      const target = e.target;
      const targetId = get().pages[get().currentPage].activeNode;
      navigator.clipboard
        .readText()
        .then(
          (clipText) => {
            const text = clipText.split("\n");
            for (let i = 0; i < text.length; i++) {
              const activeNode = get().pages[get().currentPage].activeNode;
              const txt = text[i].trim();
              console.log(!!txt, txt.length, txt !== " ");
              
              (!!txt && txt !== "" && txt.length > 1) && get().createNodeOrBlock(activeNode, { text: txt });
            }
          }
        ).finally(() => {
          if (target.textContent === '') {
            get().updateParent(targetId);
            get().deleteNode(targetId);
          }
        });
    },

    handleDrop: (e) => { },

    undos: [{ nodes: {}, blocks: [] }],
    // currentCursor: 1,
    setUndo: (data) =>
      set((state) => {
        console.log("Saving to Undos!!");
        const u = get().pages[get().currentPage].undos.slice(
          0,
          get().pages[get().currentPage].currentCursor
        );
        const db = {
          blocks: get().pages[get().currentPage].blocks,
          nodes: {
            ...get().pages[get().currentPage].nodes,
            [data.focusedId]: data.node,
          },
          focusedId: data.focusedId,
        };
        const undos = [...u, ...[db]];
        state.pages[get().currentPage].currentCursor = undos.length;
        state.pages[get().currentPage].undos = undos;
      }),

    doRedo: () =>
      set((state) => {
        const cursor = get().pages[get().currentPage].currentCursor;
        const u = get().pages[get().currentPage].undos[cursor];
        if (!u) return;
        const { blocks, nodes, focusedId } = u;
        state.pages[get().currentPage].nodes = nodes;
        state.pages[get().currentPage].blocks = blocks;
        state.pages[get().currentPage].currentCursor = cursor + 1;
        state.pages[get().currentPage].focusedNode = focusedId;
      }),

    doUndo: () =>
      set((state) => {
        const cursor = get().pages[get().currentPage].currentCursor;
        const { blocks, nodes, focusedId } =
          get().pages[get().currentPage].undos[Math.max(0, cursor - 2)];
        state.pages[get().currentPage].focusedNode = focusedId;
        state.pages[get().currentPage].nodes = nodes;
        state.pages[get().currentPage].blocks = blocks;
        state.pages[get().currentPage].currentCursor = Math.max(1, cursor - 1);
      }),

    timer: setTimeout(() => { }, 0),
    save: (data) =>
      set((state) => {
        clearTimeout(get().timer);
        state.timer = setTimeout(() => get().setUndo(data), 200);
      }),

    users: null,
    setUsers: (users) => set((state) => {
      state.users = users;
    }),
    fetchUsers: async () => {
      await fetch("https://jsonplaceholder.typicode.com/users").then((resp) => {
        return resp.json();
      }).then((data) => get().setUsers(data)
      ).catch((err) => console.log(err))
    },

    emojis: null,
    setEmojis: (emojis) => set((state) => {
      state.emojis = emojis
    }),
    fetchEmojis: async () => {
      await fetch("https://emoji-api.com/emojis?access_key=38ac03b90cf8ab6a511977fff84bb6e9a72323ed").then((resp) => {
        return resp.json();
      }).then((data) => get().setEmojis(data)
      ).catch((err) => console.log(err))
    },
    imageDialogIsOpen: null,
    setImageDialogIsOpen: (status) => set((state) => {
      state.imageDialogIsOpen = status;
    }),
  }))
);
