import React from "react";
import Nav from "./Nav";
import Contents from "./Contents";
import Footer from "./Footer";
// import Sidebar from "./Sidebar";
import CoverImage from "../components/top/CoverImage";

const BaseLayout = ({ children }) => {
  return (
    <>
      {/* <Sidebar /> */}
      <div className="flex flex-col flex-[8]">
        <Nav />

        <CoverImage />
        <Contents />

        <Footer />
      </div>
    </>
  );
};

export default BaseLayout;
