import { useState } from "react";
import { useDBStore } from "../../stores/dbStore";
import { findNodeById } from "../../helpers";

const VerticalBlock = ({ id, renderer }) => {
  const {
    setFocus,
    setActiveNode,
    createNode,
    setDestination,
    createNewBlock,
    currentPage,
    pages,
  } = useDBStore((state) => state);
  const { nodes, blocks } = pages[currentPage];
  const node = nodes[id];

  const handleClick = (e) => {
    console.log("Clicked!!");
    
    const lastChildId = node.children.slice(-1)[0];
    const lastChild = nodes[lastChildId];
    const lastEditable = findNodeById(lastChildId);
    if (lastChild.type === "paragraph" && lastEditable.textContent === "") {
      setFocus(null);
      setActiveNode(null); // For rerendering purposes
      setFocus(lastChildId);
      setActiveNode(lastChildId);
      lastEditable?.focus();
      return;
    }
    const id = e.target.parentElement.getAttribute("data-id");
    const parent = nodes[nodes[id].parent];
    const onlyChild = parent.children.length === 1;
    const index = blocks.indexOf(parent.id) + 1;
    onlyChild
      ? createNewBlock("paragraph", index)
      : createNode("paragraph", id, node.children.length);
  };

  const handleDragOver = (e) => {
    const vBlock = e.target.parentElement;
    const x = e.clientX;
    const rects = vBlock.getBoundingClientRect();
    const id = vBlock.getAttribute("data-id");
    if (Math.abs(rects.right - x) < 40) {
      document.querySelector(".activeBlock")?.classList.remove("activeBlock");
      document
        .querySelector(".activeWrapper")
        ?.classList.remove("activeWrapper");
      e.target.classList.add("activeBlock");
      setDestination(id);
      return;
    }
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
  };

  const handleDragLeave = () => {
    const target = document.querySelector(".activeWrapper");
    target?.classList.remove("activeWrapper");
    document.querySelector(".activeBlock")?.classList.remove("activeBlock");
    // setDestination(null);
  };

  return (
    <>
      <div
        key={id}
        data-item-type="SECTION"
        className="v-block min-w-[150px] pl-5 w-full h-auto flex"
        data-id={id}
      >
        <div data-id={id} className="v-block w-full h-full">
          {node?.children?.map((childId) => {
            return renderer(childId, nodes[childId].type);
          })}

          <div className="w-full min-h-3 h-full" onClick={handleClick}></div>
        </div>
        <div
          className="h-full min-w-0.5"
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
        ></div>
      </div>
      <div className="group ml-1.5 w-8" data-item-type="BAR">
        <span className="hidden cursor-col-resize group-hover:block m-auto h-full group-hover:bg-gray-500 w-0.5"></span>
      </div>
    </>
  );
};

export default VerticalBlock;
