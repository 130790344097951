import { useEffect, useState } from "react";
import { RxDragHandleDots2 } from "react-icons/rx";
import { LuPlus } from "react-icons/lu";
import { Menus } from "../constants";
import { useDBStore } from "../stores/dbStore";
import { useParams } from "react-router-dom";

export default function MenuComponent() {
  const Page = useParams();
  const {
    createNewBlock,
    focusCords,
    createNode,
    setShowMenu,
    setShowSubMenu,
    showMenu,
    showMenuButtons,
    replaceNode,
    setDragged,
    setDragging,
    destinationNode,
    moveNode,
    draggedNode,
    updateParent,
    setShowMenuButtons,
    node2VBlock,
    pages,
    currentPage,
    handleMenuClick
  } = useDBStore((state) => state);
  const { activeNode, nodes, blocks } = pages[currentPage];
  const [MenuItems, setMenuItems] = useState(Menus);

  const handleDragStart = (e) => {
    const image = document.querySelector(
      ".nodeWrapper[data-id='" + activeNode + "']"
    );
    e.dataTransfer.setDragImage(image, 0, 0);
    setShowMenuButtons(null);
    setShowSubMenu(null);
    setDragged(activeNode);
    setDragging(true);
  };

  const handleDragEnd = (e) => {
    if (destinationNode && draggedNode) {
      const destination = nodes[destinationNode];
      const destParent = destination.parent && nodes[destination.parent];
      updateParent(draggedNode);
            
      if (destination.type === "verticalBlock") {
        node2VBlock(draggedNode, destinationNode);
      } else if (destination.type === "horizontalBlock") {
        const blockIndex = blocks.indexOf(destinationNode) + 1;
        const dNode = nodes[draggedNode];
        createNewBlock(dNode.type, blockIndex, dNode);
      } else {
        const grandParent = nodes[destParent.parent];
        const blockIndex = blocks.indexOf(grandParent.id) + 1;
        const onlyChild = grandParent.children.length === 1;
        const dNode = nodes[draggedNode];

        if (onlyChild) {
          createNewBlock(dNode.type, blockIndex, dNode);
        } else {
          const index = destParent.children.indexOf(destinationNode) + 1;
          moveNode(draggedNode, destinationNode, index);
        }
      }
    }
    setDragged(null);
    setDragging(null);
  };



  const handleTyping = (e) => {
    const keywords = e.target.textContent;
    const menus = [
      ...MenuItems.filter(
        ({ name, type, description }) =>
          name.includes(keywords) ||
          type.includes(keywords) ||
          description.includes(keywords)
      ),
    ];
    setMenuItems(menus);
    document.querySelector("body").classList.add("menu-opened"); // Without this, the Keydown Event for ENTER will fire and it will recreate another extra Block/Node
    let selected = document.querySelector(".selected");
    const menuItem = document.querySelector(".menuitems").firstElementChild;
    !selected && menuItem?.classList.add("selected");
    selected = document.querySelector(".selected") || menuItem;
    if (!selected) return;
    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        selected.previousElementSibling &&
          selected.classList.remove("selected");
        selected.previousElementSibling?.classList.add("selected");
        break;
      case "ArrowDown":
        e.preventDefault();
        selected.nextElementSibling && selected.classList.remove("selected");
        selected.nextElementSibling?.classList.add("selected");
        break;

      case "Escape":
        e.preventDefault();
        selected?.parentElement.dispatchEvent(clickEvent); // For cancelation purpose
        break;

      case "Enter":
        e.preventDefault();
        selected?.dispatchEvent(clickEvent);
        break;

      default:
        // e.preventDefault();
        break;
    }
  };

  useEffect(() => {
    let fn = (e) => {
      setShowMenu(null);
      setShowSubMenu(null);
      document.removeEventListener("keydown", handleTyping, true);
      setMenuItems(Menus);
    };
    document.addEventListener("click", fn, true);
  });

  return (
    <div>
      <div
        style={{
          top: focusCords.top,
          left: focusCords.left - 48,
          // height: focusCords.height,
        }}
        className="menu absolute left-10 z-20 w-[60px]"
      >
        {!showMenu && (
          <div
            style={{ display: showMenuButtons ? "flex" : "none" }}
            className="animate__animated animate__zoomIn"
          >
            <button
              id="menuBtn"
              onClick={(e) => {
                handleMenuClick(e);
                setShowSubMenu(null);
                setShowMenu(true);
                document.addEventListener("keydown", handleTyping, true);
              }}
              className="inline-flex items-center gap-2 rounded-md py-1 px-1 text-sm/6 font-semibold shadow-inner shadow-white/10 focus:outline-none"
            >
              <LuPlus className="size-4 fill-black/60" />
            </button>
            <button
              draggable={true}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onClick={() => {
                setShowMenuButtons(null);
                setShowMenu(null);
                setShowSubMenu(true);
              }}
              as="button"
              className="inline-flex items-center gap-2 rounded-md py-1 px-1 text-sm/6 font-semibold shadow-inner shadow-white/10 focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white"
            >
              <RxDragHandleDots2 className="size-4 fill-black/80" />
            </button>
          </div>
        )}
        <div
          id="menu"
          style={{
            display: showMenu ? "block" : "none",
            top: focusCords.height + 45,
          }}
          className="relative bg-white border-b-gray-400/40 border  overflow-y-scroll scroll-m-0 scroll-smooth left-0 w-[320px] max-h-[320px] h-auto m-auto rounded-lg animate__animated animate__bounceInUp"
        >
          <div className="size-full h-10 p-3 border-b rounded-t-md flex items-center text-sm text-gray-500/80">
            {MenuItems.length > 0 ? "Basic Blocks" : "No Result Found"}
          </div>
          <div className="menuitems flex-col m-auto pt-3 border-t-gray-400/40 h-auto w-full hidden has-[div]:flex">
            {MenuItems.map(
              ({ name, icon, description, type, element, shortcut }, i) => {
                return (
                  <div
                    key={i}
                    tabIndex={0}
                    onClick={() => replaceNode(activeNode, type, { element })}
                    className="group flex w-full h-[65px] my-2 px-2 focus:bg-slate-300/20 group-has-[.selected]:bg-slate-300/20 focus:outline-none hover:bg-slate-300/20 *:my-auto justify-between"
                  >
                    <span className="h-[50px] inline-flex  text-gray-500/80 w-[60px] p-2 justify-center items-center rounded-md border mr-2.5 *:size-[80px]">
                      {icon}
                    </span>
                    <span className="w-full h-full inline-flex flex-col justify-start ali *:text-left font-light">
                      <span className="mt-1 text-gray-500/90">{name}</span>
                      <small>{description}</small>
                    </span>
                    <span className="h-full inline-flex w-full justify-center items-center">
                      <kbd className="keyboard ml-auto hidden font-sans text-xs group-hover:inline group-focus:inline">
                        {shortcut}
                      </kbd>
                    </span>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
