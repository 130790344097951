import { useLink } from "../../hooks";
import { useDBStore } from "../../stores/dbStore";

const Breadcrumb = () => {
  const { history, pages } = useDBStore((state) => state);
  const { navigateTo } = useLink();

  return (
    <div className="flex w-[80%] align-middle *:px-1 *:my-[auto]">
      {history.slice(-3).map((item) => {
        const page = pages[item];
        let title = page?.title;
        title = title ?? "Untitled";
        const elipsis = title.length > 20 ? "..." : "";
        return (
          <div>
            <button
              key={item}
              onClick={() => navigateTo(item)}
              className="font-normal p-2  text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
            >
              {`${page?.icon ?? ""}  ${title.substr(0, 20)+ elipsis}`}
            </button>
            {"   /   "}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;