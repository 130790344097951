import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { useDBStore } from "../../stores/dbStore";
import { useState } from "react";
import ContentEditable from "../content/ContentEditable";

export default function Lists({ id, renderer }) {
  const { pages, currentPage, createNode } = useDBStore((state) => state);
  const { nodes } = pages[currentPage];
  const node = nodes[id];
  const children = node.children;
  const [type] = useState("space-counter");

  const handleClick = (e) => {
    const target = e.target.closest(".nodeWrapper");
    const tId = target.getAttribute("data-id");
    const nodeIndex = node.children.indexOf(tId) + 1;
    createNode("list", id, nodeIndex, { element: node.element });
  };
  return (
    <List
      // slots={node.element}
      key={id}
      marker={node.element === "ul" ? "disc" : "space-counter"}
    >
      <ListItem key={id} sx={{ padding: 0 }}>
        <ContentEditable
          id={id}
          className="outline-none  h-[fit-content] text-normal min-w-full inline-block self-start"
          aria-placeholder="List"
        />
        <div className="size-full min-h-3" onClick={handleClick}></div>
      </ListItem>
      {children.length > 0 && (
        <List>
          <ListItem nested={true} marker="none">
            {children.map((childId) => {
              return renderer(childId, nodes[childId].type);
            })}
          </ListItem>
        </List>
      )}
    </List>
  );
}
