import React, { useEffect, useRef } from "react";
import { IoDocumentOutline } from "react-icons/io5";
import { useDBStore } from "../../stores/dbStore";
import { useLink } from "../../hooks";

const Page = ({ id }) => {
  const { pages, currentPage } = useDBStore((state) => state);
  const $this = useRef(null)
  // const { nodes } = pages[currentPage];
  const { navigateTo } = useLink();
  const page = pages[id];
  useEffect(() => {
    if (!page && $this.current) {
      setTimeout(() => {
        navigateTo(id);
      }, 1000);
    }
  }, []);
  return (
    <button ref={$this} className="w-full min-h-5 underline text-left flex">
      {page?.icon ?? <IoDocumentOutline />} {"  "}
      <button onClick={() => navigateTo(id ?? "#")} className="mx-4 text-gray-600">
        {page?.title ?? "UntitledPage"}
      </button>
    </button>
  );
};

export default Page;