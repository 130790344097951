import { useDBStore } from "./stores/dbStore";

export function useLink() {
  const {
    setCurrentPage,
    initDB,
    history,
    setHistory,
    setPreviousPage,
    currentPage,
    pages
  } = useDBStore(
    (state) => state
  );
  const navigate = (id, data = null) => {
    const page = pages[id];
    const title = page?.title ?? "UntitledPage";
    const url = `/${title}-${id}`;
    setPreviousPage(currentPage);
    setCurrentPage(id);
    !page && initDB({ id, title, href: url });
    const i = history.indexOf(id);
    const _history = i < 0 ? history : history.slice(0, i);
    setHistory([..._history, id]);
    window.history.pushState(data, title, url);
  };
  return { navigateTo: navigate };
}