import React, { useEffect, useState } from "react";
import EditorTitle from "../components/content/EditorTitle";
import Editor from "../components/Editor";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDBStore } from "../stores/dbStore";
import { useLink } from "../hooks";
const Contents = () => {
  const {
    pages,
    currentPage,
    createNewBlock,
    updatePage,
    previousPage
  } = useDBStore((state) => state);
  const { navigateTo } = useLink();
  const { id } = useParams();

  useEffect(() => {
    const page = pages[currentPage];
    if (currentPage && page) {
      if (!page.parent && previousPage) {
        updatePage(currentPage, { parent: previousPage });
      }
      if (!page.blocks || page.blocks.length === 0) createNewBlock();
      return;
    }
   
    if (!id && !currentPage) {
      const pageId = uuidv4();
      navigateTo(pageId);
    }
  }, [id, currentPage]);


  return (
    <div className="flex flex-col justify-center *:mx-auto *:w-[70%]">
      {!currentPage ? (
        <div role="status" className="w-full space-y-1 mt-3 h-auto animate-pulse">
          <div className="w-full h-8 bg-gray-100/90"></div>
          <div className="rounde-md w-full h-24 bg-gray-100/90"></div>
          <div className="w-full h-8 bg-gray-100/90"></div>
          <div className="w-full h-8 bg-gray-100/90"></div>
        </div>
      ) : (
        <>
          <EditorTitle />
          <Editor />
        </>
      )}
    </div>
  );
};

export default Contents;
